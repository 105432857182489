import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomDot from './CustomDot'; // Importe o componente CustomDot

import fliperama from '../../img/fliperama1.png'
import pt from '../../img/pt.png'
import pt2 from '../../img/pt2.png'
import mat from '../../img/mat.png'

const Wrapper = styled.div`
box-sizing: content-box
 width: 100vw;
 @media (min-width: 1200px) {
    width: 100%;


 }
`;

const Container = styled.div`
 display: flex;
 overflow: hidden;
 margin-top: -5px;
 margin-left: -8px;
 margin-right: -5px;

@media (min-width: 2560px) {
    width: 100%;
    margin: 0;
    margin-right: -6px;
    margin-top: -6px;
}

 @media (max-width: 450px) {
    width: 100%;
    margin: 0;
    margin-left: -2px;
    margin-right: 6px;
    margin-top: -6px;
 }

 @media (max-width: 375px) {
  width: 100%;
  margin: 0;
  margin-top: -4.5px;
  margin-right: 10px;
  margin-left: -2px;
 }

 }
`;

const MyCarousel = () => {
 const images = [
    fliperama,
    pt,
    pt2,
    mat,
 ];

 // Calculate the aspect ratio of the screen
 const aspectRatio = window.innerWidth / window.innerHeight;

 // Set the height of the carousel items based on the aspect ratio
 const carouselItemHeight = aspectRatio > 1.5 ? `calc(100vw / 1.5 * 0.75)` : '450px';

 return (
    <Wrapper>
      <Container>
        <Carousel
          autoPlay
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          renderIndicator={(clickHandler, isSelected, index) => (
            <CustomDot
              key={index}
              isSelected={isSelected}
              onClick={() => clickHandler(index)}
            />
          )}
          width="100%"
        >
          {images.map((image, index) => (
            <div key={index} style={{ height: carouselItemHeight }}>
              <img src={image} alt={`Image ${index + 1}`} style={{
                 objectFit: 'cover',
                 width: '100%',
                   height: '100%'
                   }} />
            </div>
          ))}
        </Carousel>
      </Container>
    </Wrapper>
 );
};

export default MyCarousel;