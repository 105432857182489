import React from 'react';
import styles from './PlansCard.module.css';

function PlansCard() {
    return (
        <div className={styles.card_container}>
            <div className={styles.card}>
                <h2>Plano Pago 1</h2>
                <p>Descrição do plano pago 1...</p>
                <a href="#" className={styles.button}>Assinar agora</a>
            </div>
            <div className={styles.card}>
                <h2>Plano Pago 2</h2>
                <p>Descrição do plano pago 2...</p>
                <a href="#" className={styles.button}>Assinar agora</a>
            </div>
            <div className={styles.card}>
                <h2>Plano Pago 3</h2>
                <p>Descrição do plano pago 3...</p>
                <a href="#" className={styles.button}>Assinar agora</a>
            </div>
        </div>
    )
}
export default PlansCard;