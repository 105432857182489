import React from 'react';
import styles from './Integrantes.module.css';

import Enzo from '../../img/Enzo.jpeg';
import Ximenes from '../../img/Ximenes.png';
import Lyncoln from '../../img/Lyncoln.png';
import Campos from '../../img/Campos.png';
import Wagner from '../../img/Wagner.png';
import Insta from '../../img/insta.png';

function Integrantes() {
  return (
    <div className={styles.grid_container}>
      <div className={styles.image_container}>
        <img src={Enzo} alt="Image 1" />
        <div className={styles.image_info}>
          <p>Enzo Rodrigues Gracindo</p>
          <p>Back-End/Search</p>
          <a
            href="https://www.instagram.com/enzo_rodriguesg/"
            target="_blank"
            className={styles.btn_instagram}
          >
            <img src={Insta} alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={styles.image_container}>
        <img src={Ximenes} alt="Image 2" />
        <div className={styles.image_info}>
          <p>Lukas Ximenes Keiga</p>
          <p>Front-End</p>
          <a
            href="https://www.instagram.com/ximenexs_/"
            target="_blank"
            className={styles.btn_instagram}
          >
            <img src={Insta} alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={styles.image_container}>
        <img src={Lyncoln} alt="Image 3" />
        <div className={styles.image_info}>
          <p>Lyncoln de Lima</p>
          <p>Front-End</p>
          <a
            href="https://www.instagram.com/seu_usuario/"
            target="_blank"
            className={styles.btn_instagram}
          >
            <img src={Insta} alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={styles.image_container}>
        <img src={Campos} alt="Image 4" />
        <div className={styles.image_info}>
          <p>Pedro Campos D. Lopes</p>
          <p>Leader/Back-End/Front-End</p>
          <a
            href="https://www.instagram.com/pedrocdlopes/"
            target="_blank"
            className={styles.btn_instagram2}
          >
            <img src={Insta} alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={styles.image_container}>
        <img src={Wagner} alt="Image 5" />
        <div className={styles.image_info}>
          <p>Wagner de Almeida Fortes</p>
          <p>Search</p>
          <a
            href="https://www.instagram.com/w4gneris_zn/"
            target="_blank"
            className={styles.btn_instagram}
          >
            <img src={Insta} alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Integrantes;