import React from 'react';

const CustomDot = ({ onClick, backgroundColor, isSelected }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const dotStyles = {
    backgroundColor: backgroundColor || (isSelected || isHovered ? '#000' : '#2c2c2c'),
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0 5px',
    cursor: 'pointer',
  };

  return (
    <div
      style={dotStyles}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};

export default CustomDot;