import MyCarousel from '../layout/Carousel'

import circulo from '../../img/fliperama.png'

import styles from './Home.module.css'

function Home() {
  return (
    <div className={styles.home_width}>
        <MyCarousel />
      <div className={styles.home_container}>
        <div className={styles.circle}>
          <img src={circulo} alt="Sua imagem" />
        </div>
        <div className={styles.text}>
          <p>O Einstein's Arcade é uma plataforma educacional inovadora que combina aprendizado e diversão. Desenvolvido como resposta à falta de métodos de ensino dinâmico e inovador, o jogo fornece uma abordagem diferenciada para envolver os estudantes. Inspirado no amplo interesse dos jovens por jogos eletrônicos, o Einstein's Arcade oferece uma experiência de aprendizagem prática e envolvente. Com personagens, cenários e pesquisas interativas, busca tornar o estudo mais agradável e motivador. A plataforma valoriza o feedback dos usuários para aprimorar constantemente a experiência educacional, representando uma alternativa inovadora para tornar o processo de aprendizagem mais interessante e envolvente.</p>
        </div>
      </div>
      </div>
  )
}

export default Home