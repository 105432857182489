import { FaWindows } from "react-icons/fa";
import { FaLinux } from "react-icons/fa";
import { FaAndroid } from "react-icons/fa";

import React from 'react';
import styles from './DownloadCard.module.css';

function DownloadCard() {
    return (
        <div className={styles.card_container}>
            <div className={styles.card}>
                <div className={styles.color_div}>
                <h2>Selecione o seu sistema operacional</h2>
                <div className={styles.card_icon}>
                    <li>
                        <a href="https://download1531.mediafire.com/fbmtcb9w79sgzaJmiA2P941Qf7u26JfflM0jaru0JiYg0_ISf8suVtmBVeAZpLUovcN83WsbNebKM6kVIMeQFETPM_m_q8sH4qp9fQ5X7ZEnvv_4HXRA9BLN2AQ_wOyDP9hE-EV4-OnOJduSx2zLvUE9iawhrHp_Siv3_3oGAgGwEQ/u4ixl1hlv0iogoe/Einstein%5C%27s+Arcade.exe">
                    <FaWindows />
                    </a>
                    </li>
                    <li>
                        <a href="#">
                    <FaLinux />
                    </a>
                    </li>
                    <li>
                        <a href="#">
                    <FaAndroid />
                    </a>
                    </li>
                </div>
                </div>
            </div>
        </div>
    )
}
export default DownloadCard;