import {Link} from 'react-router-dom'

import Container from './Container'

import styles from './Navbar.module.css'
import logo from '../../img/logo.png'

function Navbar () {

    return (
        <nav className={styles.navbar}>
        <Container>
        <Link to="/">
            <img src={logo} alt="einsteins" />
        </Link>
        <ul className={styles.list}>
            <li className={styles.item}>
            <Link to="/">Home</Link>
            </li>
            <li className={styles.item}>
            <Link to="/planos">Planos</Link>
            </li>
            <li className={styles.item}>
            <Link to="/support">Suporte</Link>
            </li>
            <li className={styles.item}>
                <Link to="/sobre">Sobre</Link>
            </li>
            <li className={styles.item}>
                <Link to="/login">Entrar</Link>
            </li>
            <li className={styles.item}>
                <Link to="/download">Download</Link>
            </li>
            
        </ul>
        </Container>
        </nav>
    )
}

export default Navbar