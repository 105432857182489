import FormCadastro from "../form/FormCadastro"

import styles from './Cadastro.module.css'

function Cadastro() {
    return (
        <div className={styles.fundo}>
        <FormCadastro btnText={"Em Breve"}/>
        </div>
    )

}

export default Cadastro