import styles from './Team.module.css'
import equipe from '../../img/equipe.jpeg'

function Team() {
    return (
        <div className={styles.team_div}>
        <div className={styles.team_text}>
            <h2>Nossa Equipe</h2>
            <p>Conheça nosso grupo de cinco estudantes determinados a deixar sua marca no mundo da educação. Unindo forças e paixões, estamos imersos na criação de um jogo educacional inovador. Cada membro traz suas habilidades únicas para esta jornada, buscando não apenas aprender, mas também transformar a experiência educativa em algo envolvente e inspirador.</p>
        </div>
        <div className={styles.team_image}>
            <img src={equipe} alt="Equipe do Projeto"/>
        </div>
    </div>
    )
}

export default Team