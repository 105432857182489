import FormLogin from "../form/FormLogin"

import styles from './Login.module.css'

function Login() {
    return (
        <div className={styles.fundo}>
        <FormLogin btnText={"Em Breve"}/>
        </div>
    )

}

export default Login