import Input from './Input'
import SubmitButton from './SubmitButton'

import styles from './FormHelp.module.css'

function FormHelp({btnText}) {
    return(
        <div className={styles.formulario}>
            <div className={styles.form_content}>
                <form action="mailto:pedrocdlopes@hotmail.com" method="post" enctype="text/plain" className={styles.form}>
                <Input 
                type="text" 
                text="Nome" 
                name="name" 
                placeholder="Insira o seu Nome"
                required
                />
            
                <Input 
                type="email" 
                text="E-mail" 
                name="email" 
                placeholder="Insira o seu E-mail"
                required
                />

                <Input 
                type="text" 
                text="Mensagem" 
                name="budget" 
                placeholder="Digite aqui sua dúvida ou sugestão"
                required
                />
            <SubmitButton text={btnText}/>
        </form>
        </div>
        </div>

        
    )
}

export default FormHelp