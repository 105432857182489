import styles from './Help.module.css'

function Help() {
    return (
        <div className={styles.duvida_text}>
            <p>Tire Suas Dúvidas: Formulário de Contato</p>
             <a>Este formulário foi criado para facilitar o esclarecimento de dúvidas. Se você possui perguntas ou precisa de informações adicionais, por favor, preencha os campos ao lado. Estamos aqui para ajudar e responderemos o mais breve possível. Agradecemos o seu contato!</a>
         </div>
    )
}

export default Help