import Integrantes from "../layout/Integrantes"
import Team from "../layout/Team"

import styles from './Sobre.module.css'

function Sobre() {
    return (
        <div className={styles.fundo}>
        <Team />
        <Integrantes />
        </div>
    )

}

export default Sobre