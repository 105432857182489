import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'


import Container from './components/layout/Container'
import Navbar from './components/layout/Navbar'
import Home from './components/pages/Home';
import Planos from './components/pages/Planos';
import Support from './components/pages/Support';
import Sobre from './components/pages/Sobre';
import Login from './components/pages/Login';
import Cadastro from './components/pages/Cadastro';
import Download from './components/pages/Download';
import Footer from './components/layout/Footer';


function App() {
  return (
    <Router>
      <Navbar />
      <Container customClass="min-height">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/planos" element={<Planos />}/>
        <Route path="/support" element={<Support />}/>
        <Route path="/sobre" element={<Sobre />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/cadastro" element={<Cadastro />}/>
        <Route path="/download" element={<Download />}/>
      </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
