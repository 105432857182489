import {Link} from 'react-router-dom'

import Input from './Input'
import SubmitButton from './SubmitButton'

import logo2 from '../../img/logo (2).png'

import styles from './FormCadastro.module.css'

function FormCadastro({btnText}) {
    return (
        <div class={styles.container}>
            <form>
                <img src={logo2} alt="Imagem de fundo" className={styles.round_image}/>
                <div className={styles.ghostMargin}></div> {/* Elemento Ghost */}

                <Input 
                    type="text" 
                    text="Nome de Usuário" 
                    name="username" 
                    placeholder="Insira o seu nome de Usuário"
                    required
                />

                <Input 
                    type="text" 
                    text="E-mail" 
                    name="e-mail" 
                    placeholder="Insira o seu E-mail"
                    required
                />

                <Input 
                    type="password" 
                    text="Senha" 
                    name="password" 
                    placeholder="Insira a sua Senha"
                    required
                />
 
                <SubmitButton text={btnText}/>

                <div className={styles.links}>
                    <span className={styles.white}>Já tem uma conta ? </span> 
                    <Link to="/login">Clique aqui</Link>
                </div> 
            </form>
        </div>
    )
}

export default FormCadastro