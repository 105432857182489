import FormHelp from "../form/FormHelp"
import Help from "../layout/Help"

import styles from './Support.module.css'

function Support() {
    return (
        <div className={styles.fundo}>
        <Help />
        <FormHelp btnText={"Em Breve"}/>

        <div class={styles.contato_text}>
            <p>CONTATO</p>
            <a>Se você tiver dúvidas ou recomendações sobre como podemos melhorar, fale conosco.</a>
        </div>

        </div>
    )

}

export default Support