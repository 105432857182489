import DownloadCard from "../layout/DownloadCard"

import styles from './Download.module.css'

function Download() {
    return (
        <div className={styles.fundo}>
        <DownloadCard />
        </div>
        
    )

}

export default Download